@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import "@styles/variables.scss";
@import "@styles/layoutCompanyWP.scss";
@import "@styles/wpElements.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $white;
  font-size: inherit;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin: 0;
  color: inherit;
  line-height: normal;
}

ul,
ol,
p {
  font-size: $fz-p-global;
  margin-bottom: 0;
  @media (max-width: $mobile) {
    font-size: $fz-p-global-mobile;
  }
}

:is(ul, ol) {
  padding-left: 22px;
  li {
    margin-bottom: 5px;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: initial;
  &:hover {
    color: initial;
  }
}

hr {
  width: 100%;
}

.hoverFocus:hover > :not(:hover),
.hoverFocus p:hover > :not(:hover) {
  opacity: 0.5;
}

.hidden {
  transition: all 0.5s;
  display: none !important;
}

.gapElementsParse {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

footer .mg_magneto_social_media_magneto-ui-magneto-social-media_1zvjc {
  gap: 1.2em;

  div {
    margin: 0;
  }
}

.has-small-font-size {
  font-size: 15px !important;
}

//custom style - header---------------------------------------------------------------------------------
header {
  position: sticky !important;
  z-index: 9 !important;
  top: 0;
}

.mg_logout_header_magneto-ui-middle-section_1cj57 {
  overflow: hidden;
  transition: 0.2s;
  height: 100%;
}

.hide-form-search {
  visibility: hidden;
}

// slider------------------------------------------------------------------------------------------
.slick-track {
  display: flex !important;
  align-items: center;
}

.centerMode {
  .slick-track {
    padding: 6rem 0;
    gap: 5px;
  }

  .slick-slide {
    transition: 0.8s;
    opacity: 0.5;
    pointer-events: none;
    @media (width <= $small-table) {
      opacity: 1;
      pointer-events: all;
    }
  }

  .slick-center {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
    transform: none;
    border-radius: 10px;
    transform: scale(1.4, 1.4);
    z-index: 2;
    opacity: 1;
    pointer-events: all;
  }

  @media (width <= $mobile) {
    .slick-track {
      padding: 0;
    }
    .slick-slide {
      opacity: 1;
      pointer-events: all;
    }

    .slick-center {
      transform: none;
    }
  }
}

// dots slider home---------------------------------------------------------------------------------
ul.dotsHome li.slick-active button {
  opacity: 0.6 !important;
}

ul.dotsHomeB2b li.slick-active button {
  opacity: 1 !important;
}
