@import "@styles/variables.scss";

.wp-block-table {
  @media (width <= $small-table) {
    overflow: auto;
    white-space: nowrap;
    display: block;
    width: 90vw;
  }
}

.wp-block-table,
.wp-block-table.is-style-regular {
  table {
    width: 100%;
    border: 1px solid $gray-6;
    border-collapse: collapse;
    font-size: 15px;
    thead {
      background-color: $magneto-primary-btn;
      color: $gray-6;
      th {
        border: 1px solid $gray-6;
        padding: 8px;
      }
    }

    tbody {
      td {
        border: 1px solid $gray-6;
        padding: 8px;
      }
    }
  }

  figcaption.wp-element-caption {
    padding: 1rem 2rem;
    text-align: center;
    a {
      text-decoration: underline;
    }
  }
}

.wp-block-table.is-style-stripes {
  // #f0f0f0
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 15px;

    thead {
      padding: 8px;
      border-bottom: 2px solid $gray-6 !important;
      th {
        padding: 8px;
      }
    }

    tbody {
      tr:nth-child(2n + 1) {
        background-color: #f0f0f0;
      }
      td {
        padding: 8px;
      }
    }
  }
}
